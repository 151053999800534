import { css } from "@emotion/react";
import { kitText } from "../../styles";

export const styles = {
  tooltipWrapper: css`
    ${kitText.variant.body}
    background-color: var(--color-primary, #000);
    color: var(--color-contrast, #fff);
    border-radius: var(--border-radii-md);
    padding: var(--spacing-8) var(--spacing-16);
    text-align: center;
  `,
  popover: css`
    padding: 0;
    margin: 0;
    word-break: break-all;
    box-shadow: none;
    .bp4-popover-arrow {
      scale: 0.52;
      &:before {
        box-shadow: none;
      }
      svg {
        path {
          fill: var(--color-primary);
        }
      }
    }
  `
};
