import { validateClickThroughUrl } from "../../../../utils";
import { shouldValidateOnLineItem } from "../../validators/generic";
export const FLIGHT_DATE_RANGE_ERROR = "Date must be within the line item range.";
/**
 * Whether the flight end date is within the line item date range
 * @param lineItem line item to validate
 * @param flight flight on line item to validate
 */
const validateFlightEndDateAgainstLineItem = (lineItem, flight) => {
    return !lineItem || !flight.endDate || flight.endDate <= lineItem.endDate
        ? undefined
        : FLIGHT_DATE_RANGE_ERROR;
};
/**
 * Whether the flight start date is within the line item date range
 * @param lineItem line item to validate
 * @param flight flight on line item to validate
 */
const validateFlightStartDateAgainstLineItem = (lineItem, flight) => {
    if (!flight.startDate || flight.startAsap || !lineItem) {
        return undefined;
    }
    if (lineItem.startAsap) {
        return flight.startDate >= new Date() ? undefined : FLIGHT_DATE_RANGE_ERROR;
    }
    return flight.startDate >= lineItem.startDate ? undefined : FLIGHT_DATE_RANGE_ERROR;
};
/**
 * Validates that each creative flight starts before its end date.
 * @param lineItem line item to validate
 * @returns
 */
export const validateFlightStartDates = (lineItem) => {
    const { attachedCreatives = [] } = lineItem;
    for (const flight of attachedCreatives) {
        const error = validateFlightStartDate(flight);
        if (error) {
            return error;
        }
    }
    return undefined;
};
/**
 * Validates that each creative flight ends after its start date.
 * @param lineItem line item to validate
 * @returns
 */
export const validateFlightEndDates = (lineItem) => {
    const { attachedCreatives = [] } = lineItem;
    for (const flight of attachedCreatives) {
        const error = validateFlightEndDate(flight);
        if (error) {
            return error;
        }
    }
    return undefined;
};
/**
 * Validates that each creative flight starts on or after the line item start date.
 * @param lineItem line item to validate
 * @returns error message if any, otherwise undefined
 */
export const validateLineItemCreativeFlightStartDatesInRange = (lineItem) => {
    const { attachedCreatives = [] } = lineItem;
    for (const flight of attachedCreatives) {
        const error = validateFlightStartDateAgainstLineItem(lineItem, flight);
        if (error) {
            return error;
        }
    }
    return undefined;
};
/**
 * Validates that each creative flight ends on or before the line item end date.
 * @param lineItem line item to validate
 * @returns error message if any, otherwise undefined
 */
export const validateLineItemCreativeFlightEndDatesInRange = (lineItem) => {
    const { attachedCreatives = [] } = lineItem;
    for (const flight of attachedCreatives) {
        const error = validateFlightEndDateAgainstLineItem(lineItem, flight);
        if (error) {
            return error;
        }
    }
    return undefined;
};
/**
 * Validates flight start date (valid date that comes before end date)
 * @param flight flight to validate
 * @returns
 */
const validateFlightStartDate = (flight) => {
    const { startAsap, startDate, endDate } = flight;
    if (!startAsap && !startDate) {
        return "Creative start date is required.";
    }
    if (!startAsap && startDate && endDate && startDate >= endDate) {
        return "Creative start date must come before end date.";
    }
    return undefined;
};
/**
 * Validates flight end date (valid date that comes after start date)
 * @param flight flight to validate
 * @returns
 */
const validateFlightEndDate = (flight) => {
    const { startDate, endDate, startAsap } = flight;
    if (!endDate) {
        return "Creative end date is required.";
    }
    if (!startAsap && startDate && endDate && startDate >= endDate) {
        return "Creative end date must come after start date.";
    }
    return undefined;
};
/**
 * Validates flight click through URL is a valid URL
 * @param flight
 * @returns
 */
const validateFlightClickThroughUrl = (flight) => {
    return flight?.clickThroughUrl
        ? validateClickThroughUrl(flight?.clickThroughUrl) || ""
        : undefined;
};
/**
 * Validates flight weight is greater than 0
 * @param flight
 * @returns
 */
const validateFlightWeight = (flight) => {
    if (!flight.weight) {
        return "Weight must be greater than 0";
    }
    return undefined;
};
/**
 * Validators for creative flight on  line item.
 * lineItem.attachedCreatives[id]
 */
export const validators = (sdk) => ({
    /**
     * Validates creative flight start date, based on startAsap, startDate, endDate, and whether in line item date range.
     */
    startDate: ({ lineItem, ...flight }) => {
        const startDateValidation = shouldValidateOnLineItem(lineItem, "startDate")
            ? validateFlightStartDate(flight)
            : undefined;
        const startDateRangeValidation = shouldValidateOnLineItem(lineItem, "attachedCreatives.startDatesInRange")
            ? validateFlightStartDateAgainstLineItem(lineItem, flight)
            : undefined;
        return startDateValidation || startDateRangeValidation;
    },
    /**
     * Validates creative flight end date, based on startAsap, startDate, endDate, and whether in line item date range.
     */
    endDate: ({ lineItem, ...flight }) => {
        const endDateValidation = shouldValidateOnLineItem(lineItem, "endDate")
            ? validateFlightEndDate(flight)
            : undefined;
        const endDateRangeValidation = shouldValidateOnLineItem(lineItem, "attachedCreatives.endDatesInRange")
            ? validateFlightEndDateAgainstLineItem(lineItem, flight)
            : undefined;
        return endDateValidation || endDateRangeValidation;
    },
    /**
     * Validates tracking pixels are valid URLs and no duplicates.
     */
    trackingPixels: ({ lineItem, ...flight }) => {
        if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.trackingPixels")) {
            return undefined;
        }
        return sdk.creatives.trackingPixels.validate(flight?.trackingPixels, "urls");
    },
    /**
     * Validates that the click through URL is a valid URL.
     */
    clickThroughUrl: ({ lineItem, ...flight }) => {
        if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.clickThroughUrl")) {
            return undefined;
        }
        return validateFlightClickThroughUrl(flight);
    },
    /**
     * Validate that the weight is greater than 0.
     */
    weight: ({ lineItem, ...flight }) => {
        if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.weight")) {
            return undefined;
        }
        return validateFlightWeight(flight);
    }
});
