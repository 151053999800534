import { DealType, FilterTypes, InventoryContentType, InventoryType } from "../../../../types";
import { lastValueFrom } from "rxjs";
const hasSuperTagDeals = async (sdk, dealIds) => {
    return await lastValueFrom(sdk.deals.find({
        where: [
            {
                field: "id",
                value: dealIds,
                type: FilterTypes.IN
            }
        ]
    })).then((deals) => !!deals.find((deal) => deal.deal?.type === DealType.SUPER_TAG));
};
export const validators = (sdk) => ({
    inventoryIds: async (item, value) => {
        const inventoryIds = Array.isArray(value) ? value : [];
        if (item.inventoryType !== InventoryType.PMP_DEALS_AND_SUPERTAGS) {
            return undefined;
        }
        if (!inventoryIds.length) {
            return "A deal must be selected.";
        }
        if (item.wlistId) {
            return "Can't select inventory packages with both deals and a publisher group.";
        }
        if (item.contentType && (await hasSuperTagDeals(sdk, inventoryIds))) {
            return "Content object cannot be applied with SuperTags.";
        }
        return undefined;
    },
    wlistId: (item, value) => {
        const wlistId = value?.trim();
        if (item.inventoryType !== InventoryType.PUBLISHER_GROUPS) {
            return undefined;
        }
        if (!wlistId) {
            return "A publisher group must be selected.";
        }
        if (item.inventoryIds?.length) {
            return "Can't select inventory packages with both deals and a publisher group.";
        }
        return undefined;
    },
    genres: (item, value) => {
        if (item.contentType !== InventoryContentType.GENRE) {
            return undefined;
        }
        if (!(Array.isArray(value) && value?.[0])) {
            return "A genre must be selected.";
        }
        if (item.languages?.[0]) {
            return "Cannot select both genre and language.";
        }
        return undefined;
    },
    languages: (item, value) => {
        if (item.contentType !== InventoryContentType.LANGUAGE) {
            return undefined;
        }
        if (!(Array.isArray(value) && value?.[0])) {
            return "A language must be selected.";
        }
        if (item.genres?.[0]) {
            return "Cannot select both genre and language.";
        }
        return undefined;
    },
    volumeCapPct: (item, value) => {
        if (!Number.isFinite(value)) {
            return "Volume cap percentage must be a number.";
        }
        if (value < 0 || value > 100) {
            return "Volume cap percentage must be between 0 and 100.";
        }
        return undefined;
    },
    priority: (item, value) => {
        if (!Number.isFinite(value)) {
            return "Priority must be a number.";
        }
        if (value < 1 || value > 5) {
            return "Priority must be between 1 and 5.";
        }
        return undefined;
    },
    contentType: async (item, value) => {
        if (!value) {
            return undefined;
        }
        if (item.inventoryIds?.length && (await hasSuperTagDeals(sdk, item.inventoryIds))) {
            return "Content object cannot be applied with SuperTags.";
        }
        return undefined;
    },
    inventoryType: (item, value) => {
        const field = value?.trim();
        if (!field) {
            return "Inventory type selection is required.";
        }
        if (!Object.values(InventoryType).includes(field)) {
            return "A valid inventory type must be selected.";
        }
        return undefined;
    },
    name: (item, value) => {
        if (!value?.trim()?.length) {
            return "Name is required.";
        }
        return undefined;
    }
});
