import { DayOfTheWeek, ObjType, OrganizationType, ServiceStatus } from "../../types";
import { cleanStringField, dollarsToSatoshis } from "../../utils";
import { isCampaign } from "./index";
export var ServiceCampaignStatus;
(function (ServiceCampaignStatus) {
    ServiceCampaignStatus[ServiceCampaignStatus["INVALID"] = 0] = "INVALID";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_LINE_ITEM"] = 1] = "MISSING_LINE_ITEM";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_CREATIVE"] = 2] = "MISSING_CREATIVE";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_WHITELIST"] = 3] = "MISSING_WHITELIST";
    ServiceCampaignStatus[ServiceCampaignStatus["READY"] = 4] = "READY";
    ServiceCampaignStatus[ServiceCampaignStatus["COMPLETED"] = 5] = "COMPLETED";
    ServiceCampaignStatus[ServiceCampaignStatus["WAITING"] = 6] = "WAITING";
    ServiceCampaignStatus[ServiceCampaignStatus["NO_ACTIVE_CREATIVE"] = 7] = "NO_ACTIVE_CREATIVE";
    ServiceCampaignStatus[ServiceCampaignStatus["LIVE_WARNING"] = 8] = "LIVE_WARNING";
    ServiceCampaignStatus[ServiceCampaignStatus["LIVE"] = 9] = "LIVE";
    ServiceCampaignStatus[ServiceCampaignStatus["PAUSED"] = 10] = "PAUSED";
    ServiceCampaignStatus[ServiceCampaignStatus["CANCELLED"] = 11] = "CANCELLED";
    ServiceCampaignStatus[ServiceCampaignStatus["ARCHIVED"] = 12] = "ARCHIVED";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_GEO"] = 13] = "MISSING_GEO";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_PRODUCT"] = 14] = "MISSING_PRODUCT";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_GOAL"] = 15] = "MISSING_GOAL";
    ServiceCampaignStatus[ServiceCampaignStatus["CANT_SET_LIVE"] = 16] = "CANT_SET_LIVE";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_FREQ_CAP"] = 17] = "MISSING_FREQ_CAP";
    ServiceCampaignStatus[ServiceCampaignStatus["MISSING_DETAILS"] = 999] = "MISSING_DETAILS";
})(ServiceCampaignStatus || (ServiceCampaignStatus = {}));
export var CampaignStatus;
(function (CampaignStatus) {
    CampaignStatus["INVALID"] = "Invalid";
    CampaignStatus["READY"] = "Ready to Set Live";
    CampaignStatus["COMPLETED"] = "Completed";
    CampaignStatus["WAITING"] = "Waiting to Start";
    CampaignStatus["NO_ACTIVE_CREATIVE"] = "No Active Creative";
    CampaignStatus["LIVE_WARNING"] = "Live (Warning)";
    CampaignStatus["LIVE"] = "Live";
    CampaignStatus["PAUSED"] = "Paused";
    CampaignStatus["CANCELLED"] = "Cancelled";
    CampaignStatus["ARCHIVED"] = "Archived";
    CampaignStatus["CANT_SET_LIVE"] = "Can't Set Live";
    // action required statuses from here
    CampaignStatus["MISSING_LINE_ITEM"] = "Missing Line Item";
    CampaignStatus["MISSING_CREATIVE"] = "Missing Creatives";
    CampaignStatus["MISSING_WHITELIST"] = "Missing Publisher Group";
    CampaignStatus["MISSING_PRODUCT"] = "Missing Product";
    CampaignStatus["MISSING_GEO"] = "Missing Geo";
    CampaignStatus["MISSING_GOAL"] = "Missing Goal";
    CampaignStatus["MISSING_FREQ_CAP"] = "Missing Frequency Cap";
    CampaignStatus["MISSING_DETAILS"] = "Missing Details";
    CampaignStatus["UNKNOWN"] = "Unknown";
})(CampaignStatus || (CampaignStatus = {}));
export var TargetingRule;
(function (TargetingRule) {
    TargetingRule["PREMION_NO_SELF_SUPPLY"] = "PREMION_NO_SELF_SUPPLY";
    TargetingRule["FIVE_MIN_SEPARATION"] = "FIVE_MIN_SEPARATION";
})(TargetingRule || (TargetingRule = {}));
/** What sort of time frame an instruction's goal is aiming for. */
export var GoalInterval;
(function (GoalInterval) {
    GoalInterval[GoalInterval["TIMEUNIT_UNSPECIFIED"] = 0] = "TIMEUNIT_UNSPECIFIED";
    GoalInterval[GoalInterval["MINUTE"] = 1] = "MINUTE";
    GoalInterval[GoalInterval["HOUR"] = 2] = "HOUR";
    GoalInterval[GoalInterval["DAY"] = 3] = "DAY";
    GoalInterval[GoalInterval["WEEK"] = 4] = "WEEK";
    GoalInterval[GoalInterval["MONTH"] = 5] = "MONTH";
    GoalInterval[GoalInterval["LIFETIME"] = 6] = "LIFETIME";
})(GoalInterval || (GoalInterval = {}));
// How far in the future to set the start date when setting live with ASAP.
export const ASAP_SET_LIVE_DATE_OFFSET = 600000;
// I haven't figured out how to spread enums and retyping CampaignStatus will produce many errors, so doubling definitions here
// we have lots of places where we have to edit the same change, there must be a better solution
const ActionRequiredStatusArray = [
    CampaignStatus.MISSING_LINE_ITEM,
    CampaignStatus.MISSING_CREATIVE,
    CampaignStatus.MISSING_WHITELIST,
    CampaignStatus.MISSING_PRODUCT,
    CampaignStatus.MISSING_GEO,
    CampaignStatus.MISSING_GOAL,
    CampaignStatus.MISSING_FREQ_CAP
];
export const isActionRequiredStatus = (status) => ActionRequiredStatusArray.includes(status);
export const isLiveStatus = (status) => [
    CampaignStatus.LIVE,
    CampaignStatus.LIVE_WARNING,
    CampaignStatus.NO_ACTIVE_CREATIVE,
    CampaignStatus.WAITING
].includes(status);
/**
 * Takes a campaign/lineitem and converts the fields they both share
 * @param base Simple transform to handle shared data betweent lineitem / Campaign
 * @returns A set of base fields transformed.
 */
export const instructionToServiceInstruction = (base) => {
    return {
        ...("id" in base && { id: base.id }),
        ...("bookedImpressions" in base && { booked: base.bookedImpressions }),
        ...("budget" in base && { budget: dollarsToSatoshis(base.budget) }),
        ...("goalInterval" in base && { goal_interval: base.goalInterval }),
        ...("name" in base && { name: base.name?.trim() || "" }),
        ...("externalOrderManagementSystemId" in base && {
            oms_id: cleanStringField(base.externalOrderManagementSystemId, "")
        }),
        ...("externalId" in base && { external_id: base.externalId?.trim() || "" }),
        ...("mediaType" in base && { media_type: base.mediaType }),
        ...("version" in base && { version: base.version }),
        ...("parent" in base && { parent: base.parent }),
        ...("frequencies" in base && { frequencies: base.frequencies }),
        ...("targetingRules" in base && { targeting_rules: base.targetingRules })
    };
};
/**
 * Give a campaign status return the correct service status.
 * @param status Campaign status to convert.
 * @returns Service status to covert too.
 */
export const campaignStatusToServiceStatus = (status) => {
    if (!status) {
        return undefined;
    }
    if (status === CampaignStatus.WAITING ||
        status === CampaignStatus.LIVE ||
        status === CampaignStatus.LIVE_WARNING ||
        status === CampaignStatus.COMPLETED ||
        status === CampaignStatus.NO_ACTIVE_CREATIVE) {
        return ServiceStatus.READY;
    }
    if (status === CampaignStatus.PAUSED) {
        return ServiceStatus.PAUSED;
    }
    if (status === CampaignStatus.ARCHIVED) {
        return ServiceStatus.ARCHIVED;
    }
    if (status === CampaignStatus.CANCELLED) {
        return ServiceStatus.CANCELLED;
    }
    return ServiceStatus.DRAFT;
};
export const serviceStatusToCampaignStatus = (status) => {
    switch (status) {
        case ServiceStatus.ARCHIVED:
            return CampaignStatus.ARCHIVED;
        case ServiceStatus.CANCELLED:
            return CampaignStatus.CANCELLED;
        case ServiceStatus.DRAFT:
            return CampaignStatus.WAITING;
        case ServiceStatus.INVALID:
            return CampaignStatus.INVALID;
        case ServiceStatus.PAUSED:
            return CampaignStatus.PAUSED;
        default:
            return CampaignStatus.UNKNOWN;
    }
};
/**
 * Covert a daypart to the expected Day part for the service
 * @param dayparts Record of exclude day parts
 * @returns Service dayparts based on operator and dayparts given
 */
export const daypartToServiceDaypart = (dayparts) => {
    if (!dayparts) {
        return [];
    }
    const parts = [];
    Object.keys(DayOfTheWeek).forEach((key) => {
        const dayOfTheWeek = DayOfTheWeek[key];
        if (dayparts[dayOfTheWeek].length > 0) {
            parts.push({
                day: dayOfTheWeek,
                hours: dayparts[dayOfTheWeek]
            });
        }
    });
    return parts;
};
/**
 * Method will convert a Service Day Part structure to a Client
 * Day Part Structure
 * @param dayParts Service Day Parts
 * @returns Client Day Parts.
 */
export const toDayPartsFromServiceDateParts = (dayParts) => {
    if (dayParts === null || dayParts === undefined) {
        return undefined;
    }
    const dp = {
        [DayOfTheWeek.MONDAY]: [],
        [DayOfTheWeek.TUESDAY]: [],
        [DayOfTheWeek.WEDNESDAY]: [],
        [DayOfTheWeek.THURSDAY]: [],
        [DayOfTheWeek.FRIDAY]: [],
        [DayOfTheWeek.SATURDAY]: [],
        [DayOfTheWeek.SUNDAY]: []
    };
    dayParts.forEach((day) => {
        switch (day.day.toLowerCase()) {
            case "monday":
                dp[DayOfTheWeek.MONDAY] = day.hours;
                break;
            case "tuesday":
                dp[DayOfTheWeek.TUESDAY] = day.hours;
                break;
            case "wednesday":
                dp[DayOfTheWeek.WEDNESDAY] = day.hours;
                break;
            case "thursday":
                dp[DayOfTheWeek.THURSDAY] = day.hours;
                break;
            case "friday":
                dp[DayOfTheWeek.FRIDAY] = day.hours;
                break;
            case "saturday":
                dp[DayOfTheWeek.SATURDAY] = day.hours;
                break;
            case "sunday":
                dp[DayOfTheWeek.SUNDAY] = day.hours;
                break;
        }
    });
    return dp;
};
export const fromServiceOwners = async (inst, crypto) => {
    let advertiserId = "";
    const stationId = [];
    let agencyId = "";
    let stationGroupId = "";
    const ownersWithTypes = [];
    if (inst.owners) {
        const decryptOwnerType = [];
        inst.owners.forEach((owner) => {
            decryptOwnerType.push(getOwnerWithType(owner, crypto));
        });
        const owners = await Promise.all(decryptOwnerType);
        owners.forEach((owner) => {
            ownersWithTypes.push(owner);
            if (owner.OrganizationType === OrganizationType.ADVERTISER) {
                advertiserId = owner.id;
            }
            else if (owner.OrganizationType === OrganizationType.AGENCY && inst.parent !== owner.id) {
                agencyId = owner.id;
            }
            else if (owner.OrganizationType === OrganizationType.STATION) {
                stationId.push(owner.id);
            }
            else if (owner.OrganizationType === OrganizationType.STATION_GROUP) {
                stationGroupId = owner.id;
            }
        });
    }
    return {
        advertiserId,
        stationId,
        agencyId,
        stationGroupId,
        ownersWithTypes
    };
};
export const toServiceOwners = (inst) => {
    const owners = new Set();
    if (isCampaign(inst) && inst.organizationId) {
        owners.add(inst.organizationId);
    }
    if (inst.stationId) {
        if (typeof inst.stationId === "string") {
            owners.add(inst.stationId);
        }
        else {
            inst.stationId.forEach((id) => owners.add(id));
        }
    }
    if (inst.agencyId) {
        owners.add(inst.agencyId);
    }
    if (inst.advertiserId) {
        owners.add(inst.advertiserId);
    }
    return Array.from(owners);
};
/**
 * Method to handle decrypting owner information from the minted key
 * @param ownerId Owner id to decrypt the Org and Obj type of
 * @param crypto a configure cryptography
 * @returns Owner object with the decrypted data
 */
export const getOwnerWithType = async (ownerId, crypto) => {
    return new Promise((resolve, reject) => {
        crypto
            .getTypeFromKey(ownerId)
            .then((keyType) => {
            if (keyType === ObjType.ORG) {
                crypto
                    .getOrganizationTypeFromOrgKey(ownerId)
                    .then((OrganizationType) => {
                    resolve({
                        id: ownerId,
                        keyType,
                        OrganizationType
                    });
                })
                    .catch((error) => {
                    reject(error);
                });
            }
            else {
                resolve({
                    id: ownerId,
                    keyType
                });
            }
        })
            .catch((error) => {
            reject(error);
        });
    });
};
