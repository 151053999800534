/** inventory_packages can be added to many of our main data types — such as: insts, products, deals, etc. */
export var InventoryType;
(function (InventoryType) {
    InventoryType["PUBLISHER_GROUPS"] = "Publisher Groups";
    InventoryType["PMP_DEALS_AND_SUPERTAGS"] = "PMP Deals & SuperTags";
})(InventoryType || (InventoryType = {}));
// TODO combine InventoryContentType and ContentType https://madhive.atlassian.net/browse/INPACK-1190
export var InventoryContentType;
(function (InventoryContentType) {
    InventoryContentType["GENRE"] = "Genre";
    InventoryContentType["LANGUAGE"] = "Language";
})(InventoryContentType || (InventoryContentType = {}));
/**
 * Convert a ServiceInventoryPackages to InventoryPackages.
 * @param inventory_packages The ServiceInventoryPackages to convert.
 * @returns The InventoryPackages.
 */
export const serviceToInventoryPackages = (inventory_packages) => (inventory_packages || []).map((inventory_package) => {
    let inventoryType;
    if (inventory_package.wlist_id) {
        inventoryType = InventoryType.PUBLISHER_GROUPS;
    }
    else if (inventory_package.inventory_ids?.length) {
        inventoryType = InventoryType.PMP_DEALS_AND_SUPERTAGS;
    }
    let contentType;
    if (inventory_package.genres?.length) {
        contentType = InventoryContentType.GENRE;
    }
    else if (inventory_package.languages?.length) {
        contentType = InventoryContentType.LANGUAGE;
    }
    return {
        id: inventory_package?.id,
        wlistId: inventory_package?.wlist_id,
        inventoryIds: inventory_package.inventory_ids,
        genres: inventory_package?.genres,
        languages: inventory_package?.languages,
        volumeCapPct: inventory_package?.volume_cap_pct
            ? inventory_package?.volume_cap_pct / 100
            : undefined,
        maxEcpm: inventory_package?.max_ecpm,
        priority: inventory_package?.priority,
        name: inventory_package.name,
        contentType,
        inventoryType,
        publisherIds: inventory_package?.publisher_ids
    };
});
/**
 * Convert an InventoryPackages to ServiceInventoryPackages.
 * @param inventoryPackages The InventoryPackages to convert.
 * @returns The ServiceInventoryPackages.
 */
export const inventoryPackagesToService = (inventoryPackages) => (inventoryPackages || []).map((inventoryPackage) => ({
    id: inventoryPackage?.id,
    name: inventoryPackage?.name,
    wlist_id: inventoryPackage?.wlistId,
    inventory_ids: inventoryPackage?.inventoryIds,
    genres: inventoryPackage?.genres,
    languages: inventoryPackage?.languages,
    volume_cap_pct: inventoryPackage?.volumeCapPct
        ? Math.round(inventoryPackage?.volumeCapPct * 100)
        : undefined,
    max_ecpm: inventoryPackage?.maxEcpm,
    priority: inventoryPackage?.priority,
    publisher_ids: inventoryPackage?.publisherIds
}));
